// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

// Libraries
import $ from 'jquery';
import './loadFoundation';

// Custom Modules
import Nav from '../_modules/nav/nav';
import Teaser from '../_modules/teaser/teaser';
import Tiles from '../_modules/tiles/tiles';
import Video from '../_modules/video/video';
import Parallax from '../_modules/parallax/parallax';

$(() => {
  // Init Modules
  new Nav();
  new Teaser();
  new Tiles();
  new Video();
  new Parallax();

  // Detect iOS via UA (bad)
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (iOS) {
    $('body').addClass('ios');
  }
});
