'use strict';

import $ from 'jquery';
import Autoresize from '../autoresize/autoresize';
import 'slick-carousel';
import Nav from '../nav/nav';
import getSearch from '../../_scripts/getSearchParameter.js';

// Constructor
var Teaser = function() {

  // Get Nav & Autoresize instance
  var nav = new Nav();
  var autoresize = new Autoresize();

  // Initialize Video Backgrounds
  $('.teaser__video').each((function() {
    autoresize.add($(this), 1440, 900);
  }));

  var $teaser = $('.teaser');
  var $slider = $('.teaser--slider');
  // Has Slider
  if ($slider.length > 0) {
    var $slides = $('.teaser__slide');

    // === References ===
    var reference_selector = '.teaser__reference';
    var reference_toggleclass = 'teaser__reference--closed';
    function toggleReference($ref, enable) {
      if (typeof $ref === 'number') {
        $ref = $(".teaser__slide").get($ref).find(reference_selector);
      }
      if (enable === undefined) {
        enable = $ref.hasClass(reference_toggleclass);
      }

      $ref.toggleClass(reference_toggleclass, !enable);
      referenceOpen = enable;
      checkInverted();
    }

    function isReferenceOpen($elem) {
      var $ref = $elem.find(reference_selector);
      return $ref.length > 0 && !$ref.hasClass(reference_toggleclass);
    }

    $(".teaser__reference__brand").click(function(){
      toggleReference($(this).parent(reference_selector));
    });

    // === Kayboard Navigation ===
    var keyboardNavigationEnabled = true;
    if (keyboardNavigationEnabled) {
      $(window).on('keydown', function(e) {
        switch(e.keyCode) {
        case 37: // left
          $slider.slick('slickPrev');
          break;
        case 39: // right
          $slider.slick('slickNext');
          break;
        }
      });
    }


    // === Inverting the Navigation ===
    var slideInverted = false;
    var referenceOpen = false;
    function checkInverted(first) {
      nav.setInverted(slideInverted || referenceOpen, first);
    }

    // === Slider Functions ===
    function onSlideLeave(event, slick, currentIndex, nextIndex) {
      var $currentSlide = $(slick.$slides.get(currentIndex));
      var $nextSlide = $(slick.$slides.get(nextIndex));

      // Invert the navi depending on the current slide

      slideInverted = $nextSlide.data('inverted');
      referenceOpen = isReferenceOpen($nextSlide);
      checkInverted();

      // Automatically pause a video when leaving the slide
      var $video = $currentSlide.find('.teaser__video');
      if ($video.length > 0) {
        $video.get(0).pause();
      }

    }

    function onSlideEnter(event, slick, currentIndex) {
      var $currentSlide = $(slick.$slides.get(currentIndex));

      // Automatic progress of teaser slider
      var auto = $currentSlide.data('auto');
      if (auto && auto !== 'video') {
        slick.setOption('autoplaySpeed', auto);
        slick.play();
      } else { slick.pause(); }

      // Automatically start playing a video when it is reached
      var $video = $currentSlide.find('.teaser__video');
      if ($video.length > 0) {
        $video.get(0).play();

        if (auto === 'video') { // continue after video
          $video.on('ended', () => {
            slick.next();
          });
        }
      }

    }

    function onInit(event, slick) {
      var $firstSlide = $(slick.$slides.get(slick.currentSlide));
      // Initialize inverted
      slideInverted = $firstSlide.data('inverted');
      checkInverted();

      var $firstSlideRef = $firstSlide.find(reference_selector);
      if ($firstSlideRef.length > 0) {
        toggleReference($firstSlideRef, true);
      }

      onSlideEnter(event, slick, slick.currentSlide);
    }

    // === Links ===
    // --- Deep Linking ---
    var initialSlide = getSearch('slide');
    if (!isNaN(initialSlide)) {
      initialSlide = parseInt(initialSlide) - 1; // use 1 based index
      initialSlide = Math.min($slides.length - 1, Math.max(0, initialSlide));
    } else {
      initialSlide = 0;
    }
    // --- Fast Links on the page ---
    $teaser.find('a[data-slide-to]').click(function() {
      $slider.slick('slickGoTo', $(this).data('slide-to') - 1); // 1 based to match nav indicators
    });

    $slider
      // Register Events
      .on('init', onInit)
      .on('afterChange', onSlideEnter)
      .on('beforeChange', onSlideLeave)
      // Init Slick
      .slick({
        accessibility: !keyboardNavigationEnabled,
        dots: true,
        arrows: false,
        adaptiveHeight: true,
        initialSlide: initialSlide,
        infinite: $slides.length > 2 // um Bug mit Slide State zu verhindern
      });

  } else {
    // Just a simple Teaser without a slider
    nav.setInverted($teaser.data('inverted'), true);
  }

  // Scrolldown
  var $body = $('body');
  $('.teaser__scrolldown').click(function() {
    $body.stop().animate({scrollTop: $teaser.height()}, 500, 'swing');
  });
};

module.exports = Teaser;
