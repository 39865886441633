'use strict';

import $ from 'jQuery';
import Rellax from 'rellax'; // will be initialized automatically

var Parallax = function() {
  if ($('.parallax').length > 0) {
    new Rellax('.parallax', {
      speed: -2
    });
  }
};

module.exports = Parallax;