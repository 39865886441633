// from: https://stackoverflow.com/questions/523266/how-can-i-get-a-specific-parameter-from-location-search

function getSearchParameter(paramName) {
  var searchString = window.location.search.substring(1),
    i, val, params = searchString.split('&');

  for (i=0;i<params.length;i++) {
    val = params[i].split('=');
    if (val[0] == paramName) {
      return val[1];
    }
  }
  return undefined;
}

module.exports = getSearchParameter;